import React from "react";
import {Outlet} from "react-router-dom";
import NavbarComponent from "../components/navbar";
import FooterComponent from "../components/footer";
import DialogComponent from "../components/dialog";
import { ToastContainer } from 'react-toastify';

const Layout = () => {
    return (<>
        <NavbarComponent/>
        <Outlet/>
        <FooterComponent/>
        <DialogComponent/>

        <ToastContainer />
    </>)
}

export default Layout;