import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

const OrderComponent = () => {
    const { t } = useTranslation();

    return (
        <Button data-bs-toggle="modal" className="btn-yellow" data-bs-target="#checkout">
            {t('button')}
        </Button>
    );
};

export default OrderComponent;
