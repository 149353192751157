import React, {useState} from 'react';
import {Web3Button, ConnectWallet, useAddress, useSwitchChain, ChainId, useChain, useChainId} from '@thirdweb-dev/react'
import {toast} from "react-toastify";
import {Col, Row} from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as ReactPlusIcon} from "../images/checkout/plus.svg";
import {ReactComponent as ReactMinusIcon} from "../images/checkout/minus.svg";
import {ReactComponent as ReactWalletConnectIcon} from "../images/checkout/wallet-connect.svg";
import {ReactComponent as ReactMetamaskIcon} from "../images/checkout/metamask.svg";
import {ReactComponent as ReactCoinBaseIcon} from "../images/checkout/coinbase.svg";
import {ReactComponent as ReactVisaIcon} from "../images/checkout/card_visa.svg";
import {ReactComponent as ReactMasterCardIcon} from "../images/checkout/card_mc.svg";
import {ReactComponent as ReactAmericanExpressIcon} from "../images/checkout/card_ae.svg";
import {ReactComponent as ReactSimpleCardIcon} from "../images/checkout/card_simple.svg";
import {ReactComponent as ReactApplePayIcon} from "../images/checkout/apple-pay.svg";
import {ReactComponent as ReactGooglePayIcon} from "../images/checkout/google-pay.svg";

function DialogComponent() {
    const [quantity, setQuantity] = useState(1, 100);
    const [price, setPrice] = useState(0.0055);
    const {t} = useTranslation();
    const address = useAddress();
    const switchChain = useSwitchChain();
    const chainId = useChainId();

    const handleIncrease = () => {
        if (quantity < 100) {
            setQuantity(quantity + 1);
        }
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleInputChange = (event) => {
        const value = parseInt(event.target.value);
        if (value >= 0 && value <= 100) {
            setQuantity(value);
        } else if (value > 100) {
            setQuantity(100);
        } else {
            setQuantity(0);
        }
    };

    return (
        <React.Fragment>
            <div className="modal modal-lg fade" id="checkout" tabIndex="-1" aria-labelledby="checkoutLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        <div className="modal-body text-center">
                            <div className="head">
                                <h2 className="mb-3">{t('checkout.title')}</h2>
                                <p className="mb-3">{t('checkout.description')}</p>

                                <div className="quantity">
                                    <button onClick={handleDecrease}>
                                        <ReactMinusIcon/>
                                    </button>
                                    <input
                                        type="text"
                                        className="form-control d-inline-block"
                                        value={quantity}
                                        onChange={handleInputChange}
                                    />
                                    <button onClick={handleIncrease}>
                                        <ReactPlusIcon/>
                                    </button>
                                </div>

                                <p className="price mb-0">
                                    <strong>{parseFloat((price * quantity).toFixed(4))} ETH</strong> ≈ {parseFloat((10 * quantity).toFixed(4))} $
                                </p>
                            </div>

                            <div className="foot">
                                <Row xs={1} lg={2} className="g-0 g-lg-5">
                                    <Col className="mb-4 mb-lg-0">
                                        <p className="text-h">{t('checkout.crypto-wallet.title')}</p>
                                        <p>{t('checkout.crypto-wallet.description')}</p>

                                        <ConnectWallet
                                            dropdownPosition={{
                                                side: "top", // "top" | "bottom" | "left" | "right";
                                                align: "center", // "start" | "center" | "end";
                                            }}
                                            theme="light"
                                            modalTitle={t('checkout.crypto-wallet.choose')}
                                            btnTitle={t('checkout.crypto-wallet.connect')}
                                            className="btn-buy w-100 mb-3"
                                        />

                                        {address && chainId !== ChainId.Mumbai ? <button className='btn-buy w-100'
                                                                                         onClick={() => switchChain(ChainId.Mumbai)}>
                                            {t('checkout.crypto-wallet.switch-network')}
                                        </button> : address && <Web3Button
                                            theme="dark"
                                            className='btn-buy w-100'
                                            contractAddress={process.env.REACT_APP_CONTRACT}
                                            action={async (contract) =>
                                                contract.erc721.claim(quantity)
                                            }
                                            onSuccess={(result) => {
                                                toast.success(t('checkout.info.success'), {
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "colored",
                                                });
                                            }}
                                            onError={(error) => {
                                                toast.error(t('checkout.info.error'), {
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "colored",
                                                });
                                            }}
                                        >
                                            {t('checkout.crypto-wallet.buy')}
                                        </Web3Button>}


                                        <Row xs={2} lg={2}
                                             className="mt-4 d-flex text-center align-items-center justify-content-center">
                                            <Col className="mb-4">
                                                <a href="https://walletconnect.com/" target="_blank">
                                                    <ReactWalletConnectIcon/>
                                                </a>
                                            </Col>
                                            <Col className="mb-4">
                                                <a href="https://metamask.io/" target="_blank">
                                                    <ReactMetamaskIcon/>
                                                </a>
                                            </Col>
                                            <Col>
                                                <a href="https://www.coinbase.com/wallet" target="_blank">
                                                    <ReactCoinBaseIcon/>
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <p className="text-h">{t('checkout.card.title')}</p>
                                        <p>{t('checkout.card.description')}</p>
                                        <button className='btn-buy w-100'>{t('checkout.card.buy')}</button>

                                        <Row>
                                            <Col xs={10} lg={8} className="m-auto">
                                                <Row xs={3} lg={3}
                                                     className="mt-4 gx-1 d-flex text-center align-items-center justify-content-center">
                                                    <Col className="mb-3">
                                                        <a href="https://walletconnect.com/" target="_blank">
                                                            <ReactVisaIcon/>
                                                        </a>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <a href="https://metamask.io/" target="_blank">
                                                            <ReactAmericanExpressIcon/>
                                                        </a>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <a href="https://www.coinbase.com/wallet" target="_blank">
                                                            <ReactMasterCardIcon/>
                                                        </a>
                                                    </Col>
                                                    <Col>
                                                        <a href="https://www.coinbase.com/wallet" target="_blank">
                                                            <ReactSimpleCardIcon/>
                                                        </a>
                                                    </Col>
                                                    <Col>
                                                        <a href="https://www.coinbase.com/wallet" target="_blank">
                                                            <ReactApplePayIcon/>
                                                        </a>
                                                    </Col>
                                                    <Col>
                                                        <a href="https://www.coinbase.com/wallet" target="_blank">
                                                            <ReactGooglePayIcon/>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DialogComponent;