import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Container, Row, Col, Nav, Navbar} from 'react-bootstrap';
import {ReactComponent as ReactWalletIcon} from "../images/wallet.svg";
import {ReactComponent as ReactLogo} from "../images/logo.svg";

const NavbarComponent = () => {
    const [navbar, setNavbar] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    }, [])

    const handleLinkClick = () => {
        setIsExpanded(false);
    };

    const handleLanguageChange = () => {
        const newLanguage = i18n.language === 'en' ? 'ua' : 'en';
        i18n.changeLanguage(newLanguage);

        const path = newLanguage === 'ua' ? '/' : `/${newLanguage}`;
        window.location.pathname = path;
    };

    const changeBackground = () => {
        if (window.scrollY >= 70) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    return (
        <div className="header position-absolute w-100">
            <div className="top">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={6} className="mb-3 mb-lg-0">
                            <a className="logo" href="/">
                                <ReactLogo/>
                            </a>
                        </Col>
                        <Col xs={6} className="text-end">
                            <div className="phone">0 800 300 545</div>
                            <div className="email">
                                <a href="mailto:ukrposhta@ukrposhta.ua">ukrposhta@ukrposhta.ua</a>
                            </div>
                            <div className="email-text">
                                <a href="https://ukrposhta.ua/ua/kontakt-tsentr" target="_blank">
                                    {t('email-text')}
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Navbar expand="lg"
                    variant="light"
                    fixed={navbar ? "top" : ""}
                    expanded={isExpanded}
                    onToggle={() => setIsExpanded(!isExpanded)}
                    className={navbar ? "navbar-light p-0" : "navbar-light p-0"}>
                <Container>
                    <Navbar.Toggle aria-controls="navbarSupportedContent"/>
                    <div className="d-flex order-0 order-lg-1">
                        <button className="btn-lang" onClick={handleLanguageChange}>
                            {i18n.language === 'en' ? 'UA' : 'EN'}
                        </button>
                        <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#checkout">
                            <span>{t('button-wallet')}</span>
                            <ReactWalletIcon/>
                        </button>
                    </div>
                    <Navbar.Collapse id="navbarSupportedContent" className="order-lg-0">
                        <Nav className="me-auto mb-2 mb-lg-0">
                            {t('header-menu', {returnObjects: true}).map((item, index) => (
                                <Nav.Link key={index} href={item.url} onClick={handleLinkClick}>
                                    {item.name}
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavbarComponent;