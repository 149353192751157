import React from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/object.gltf");
  return (
    <group {...props} dispose={null}>

      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.0045826, 0.0031691, 0.0038188]}  >
        <mesh
          geometry={nodes.Plane_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          geometry={nodes.Plane_2.geometry}
          material={materials.Plastic}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/object.gltf");
