import React from 'react';
import {HashLink as Link} from "react-router-hash-link";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from 'react-bootstrap';
import {ReactComponent as FacebookIcon} from "../images/social/facebook.svg";
import {ReactComponent as InstagramIcon} from "../images/social/instagram.svg";
import {ReactComponent as TelegramIcon} from "../images/social/telegram.svg";
import {ReactComponent as TwitterIcon} from "../images/social/twitter.svg";
import {ReactComponent as FlogoIcon} from "../images/f-logo.svg";

const FooterComponent = () => {
    const {t} = useTranslation();

    return (
        <footer>
            <Container>
                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <Col className="mb-4 mb-lg-0 text-center text-lg-left">
                        <FlogoIcon/>
                    </Col>
                    <Col className="mb-4 mb-lg-0">
                        <ul className="menu">
                            {t('footer-menu', {returnObjects: true}).map((item, index) => (
                                <li key={index}>
                                    <Link key={index} to={item.url}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col className="mb-3 mb-lg-0 text-center text-lg-end">
                        <ul className="social">
                            <li>
                                <a href="https://www.facebook.com/ukrposhta" target="_blank" rel="noreferrer">
                                    <FacebookIcon/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/ukrposhta/" target="_blank" rel="noreferrer">
                                    <InstagramIcon/>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/ukrposhta_news" target="_blank" rel="noreferrer">
                                    <TelegramIcon/>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/ukrposhta" target="_blank" rel="noreferrer">
                                    <TwitterIcon/>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default FooterComponent;