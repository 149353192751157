import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationUk from './translations/translation-ua.json';
import translationEn from './translations/translation-en.json';

// Отримуємо мову з URL
const urlLanguage = window.location.pathname.startsWith('/en') ? 'en' : 'ua';

i18n.use(initReactI18next).init({
    resources: {
        ua: {
            translation: translationUk
        },
        en: {
            translation: translationEn
        }
    },
    lng: urlLanguage,
    fallbackLng: 'ua',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
