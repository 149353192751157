import React, {Suspense, useRef} from "react";
import {Container, Row, Col} from 'react-bootstrap';
import ReactPlayer from "react-player"
import Order from "../components/order";
import {ReactComponent as ReactFirstIcon} from "../images/financing/1.svg";
import {ReactComponent as ReactSecondIcon} from "../images/financing/2.svg";
import {ReactComponent as ReactThirdIcon} from "../images/financing/3.svg";
import {ReactComponent as ReactFourthIcon} from "../images/financing/4.svg";
import {useTranslation} from 'react-i18next';
import {Canvas} from '@react-three/fiber'
import {OrbitControls, Stage} from '@react-three/drei'
import {Model} from '../components/Model'

const HomePage = () => {
    const {t} = useTranslation();
    const ref = useRef();

    const iconComponents = {
        ReactFirstIcon: ReactFirstIcon,
        ReactSecondIcon: ReactSecondIcon,
        ReactThirdIcon: ReactThirdIcon,
        ReactFourthIcon: ReactFourthIcon
    };

    return (<div>
        <div className="home" id="home">
            <Container>
                <Row className="block text-center">
                    <Col md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}}>
                        <div className="text">
                            <p className="text-uppercase">{t('home.title')}</p>

                            <div className="small">
                                <p className="mb-0">{t('home.text1')}</p>
                                <p className="mb-0">{t('home.text2')}</p>
                                <p className="mb-5">{t('home.text3')}</p>
                            </div>
                        </div>

                        <Order/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="ruswarship" id="ruswarship">
            <Container>
                <Row>
                    <Col xs={12} lg={9} className="m-auto text-center">
                        <p className="title">{t('ruswarsip.title')}</p>
                        <p className="mb-0">{t('ruswarsip.text1')}</p>
                        <p className="mb-0">{t('ruswarsip.text2')}</p>
                        <p className="mb-0 mb-lg-5">{t('ruswarsip.text3')}</p>

                        <ReactPlayer className="video m-auto" url="video/video.mp4" width="100%" controls={true}/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="stamp" id="stamp">
            <Container>
                <Row className="text-center">
                    <Col xs={12} lg={8} className="m-auto">
                        <p className="mb-5">{t('marka.title')}</p>
                        <div className="object">
                            <Canvas shadows dpr={[1, 2]} camera={{fov: 25}}>
                                <Suspense fallback={null}>
                                    <Stage controls={ref} preset="soft" intensity={3.4} shadows={false}
                                           environment="forest">
                                        <Model/>
                                    </Stage>
                                </Suspense>
                                <OrbitControls ref={ref} enableZoom={false}/>
                            </Canvas>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="image" id="image">
            <Container className="text-center">
                <p className="title">{t('what-mark.title')}</p>

                <Row xs={2} lg={6}>
                    {t('what-mark.array', { returnObjects: true }).map((item, index) => (
                        <Col key={index} className="mb-5">
                            <img src={item.image} alt=""/>
                            <span>{item.name}</span>
                        </Col>
                    ))}
                </Row>

                <Row>
                    <Col xs={12} lg={7} className="m-auto">
                        <p className="text mb-0">{t('what-mark.text')}</p>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="donations" id="donations">
            <Container>
                <Row>
                    <Col xs={12} lg={9} className="m-auto">
                        <p className="title mb-5">
                            {t('financing.title')}
                        </p>

                        <img src="/images/financing.jpg" alt=""/>

                        <Row className="decoration row-cols-2">
                            {t('financing.array', { returnObjects: true }).map((item, index) => {
                                const IconComponent = iconComponents[item.icon];

                                return (
                                <Col key={index} className="with-m">
                                    <div className="item">
                                        <div className="icon">
                                            {IconComponent && <IconComponent />}
                                        </div>
                                        <span>{item.name}</span>
                                    </div>
                                </Col>
                                );
                            })}
                        </Row>
                        <Order/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="faq" id="faq">
            <Container>
                <p className="title">{t('faq.title')}</p>
                <Row>
                    <Col xs={12} lg={9} className="m-auto">
                        <Row xs={1} md={2}>
                            {t('faq.array', { returnObjects: true }).map((item, index) => (
                                <Col key={index} className="mb-4">
                                    <div className="item h-100">
                                        <p>{item.title}</p>
                                        <span>{item.text}</span>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="team" id="team">
            <Container>
                <p className="title mb-5">{t('teams.projects.title')}</p>
                <Row className="row-cols-2 row-cols-lg-5 justify-content-center">
                    {t('teams.projects.array', { returnObjects: true }).map((item, index) => (
                        <Col className="mb-3 mb-lg-0" key={index}>
                            <div className="employee">
                                <img src={item.image} alt=""/>
                                <p className="name mb-2">{item.name}</p>
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    </div>)
}

export default HomePage