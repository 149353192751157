import "./App.scss";
import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Layout from "./layouts/layout";
import HomePage from "./pages/home.page";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
    return (
        <div className="App">
            <Router>
                <I18nextProvider i18n={i18n}>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<HomePage />} />
                            <Route path="en" element={<HomePage />} />
                        </Route>
                    </Routes>
                </I18nextProvider>
            </Router>
        </div>
    );
}

export default App;
